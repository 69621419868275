import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import Icon, {
  LoadingOutlined,
  SearchOutlined,
  CopyOutlined,
  CalendarOutlined,
  MinusOutlined
} from "@ant-design/icons";
import {
  Tooltip,
  Tag,
  message,
  DatePicker,
  Pagination,
  Table,
  Layout
} from "antd";
import {
  SORT_DIRECTIONS,
  newCaseState,
  surgeryStatusFilters,
  caseStatusFilters,
  sortFields,
  BaseButton,
  InputSearch,
  parseInputData,
  parseDateToFilterFormat,
  SHORT_DATE_FORMAT,
  setDateFilter,
  SelectSearch,
  copyToClipboard,
  PAGINATION_SIZE,
  requestStatus,
  SURGERY_CASE_ATTR,
  FIRST_PAGE
} from "gsi-ui-components";
import { ReactComponent as EnabledShareCase } from "../../resources/shareCases/EnabledShareCase.svg";
import { ReactComponent as DisabledShareCase } from "../../resources/shareCases/DisabledShareCase.svg";
import { ReactComponent as SharedCase } from "../../resources/shareCases/SharedCase.svg";
import {
  getCaseSearchPath,
  getShareCaseTooltip,
  getCompressedCaseNumber
} from "../../helpers/caseHelper";
import { filterCases, getNextRequestQuery } from "./caseListHelper";
import { BUTTON_CONSTS } from "../../helpers/consts";
import { getCaseList, clearCaseList, getTotalCases } from "../../actions/cases";
import { clearShareCaseStatus } from "../../actions/shareCase";
import { updateCloneMessageRef } from "../../actions/acceptCase";
import Header from "../layout/Header";
import ShareCaseModal from "../common/ShareCaseModal";
import { websocketClearMessage } from "../../actions/websocket";
import "./CaseList.less";

const { Content } = Layout;

const sortDirectionsOptions = [SORT_DIRECTIONS.ASCEND, SORT_DIRECTIONS.DESCEND];

const { RangePicker } = DatePicker;

const inputSearch = (filter, placeholder) => (
  <InputSearch
    setSelectedKeys={filter.setSelectedKeys}
    selectedKeys={filter.selectedKeys}
    confirm={filter.confirm}
    clearFilters={filter.clearFilters}
    placeholder={placeholder}
  />
);

const selectSearch = (
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  options,
  selectedDropdownFilters,
  filter
) => (
  <SelectSearch
    filter={filter}
    selectedDropdownFilters={selectedDropdownFilters}
    options={options}
    clearFilters={clearFilters}
    confirm={confirm}
    setSelectedKeys={setSelectedKeys}
    selectedKeys={selectedKeys}
  />
);
const iconFilteredCalendar = filtered => (
  <CalendarOutlined className={filtered ? "filtered-date" : ""} />
);

const iconFiltered = filtered => (
  <SearchOutlined className={filtered ? "filtered-date" : ""} />
);

const CaseList = ({
  history,
  getCaseList,
  getTotalCases,
  caseListData,
  totalCases,
  status,
  caseCountStatus,
  clearCaseList,
  clearShareCaseStatus,
  sharedCases,
  updateCloneMessageRef,
  acceptCaseInfo,
  websocketUpdate,
  websocketClearMessage
}) => {
  const selectedDropdownFilters = useRef({
    [SURGERY_CASE_ATTR.SURGERY_STATUS]: [],
    [SURGERY_CASE_ATTR.CASE_STATUS]: []
  });
  const casesInfo = useRef({});
  const activeFilters = useRef("");
  const isShareCaseRequested = useRef(false);
  const isCopyCaseNumber = useRef(false);
  const [filtersCleared, setFiltersCleared] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: SURGERY_CASE_ATTR.LAST_MODIFIED,
    order: SORT_DIRECTIONS.DESCEND
  });
  const [shareCaseModalVisible, setShareCaseModalVisible] = useState(false);
  const [caseNumber, setCaseNumber] = useState("");
  const [caseId, setCaseId] = useState(null);
  const [caseList, setCaseList] = useState(parseInputData(caseListData));
  const [activeFiltersObject, setActiveFiltersObject] = useState({
    state: []
  });
  const [selectedPageSize, setSelectedPageSize] = useState(PAGINATION_SIZE);
  const [currentPage, setCurrentPage] = useState(FIRST_PAGE);

  useEffect(() => {
    clearCaseList();
    clearShareCaseStatus();
    getCaseList();
    getTotalCases();
    if (acceptCaseInfo.messageRef) {
      updateCloneMessageRef(acceptCaseInfo.messageRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCaseList(parseInputData(caseListData));
  }, [caseListData]);

  useEffect(() => {
    const caseNumberUpdate = websocketUpdate?.message?.caseNumber;
    const caseStatusUpdate = websocketUpdate?.message?.status;
    const caseEntryIndex = caseListData.findIndex(
      entry => entry.caseNumber === caseNumberUpdate
    );

    if (caseEntryIndex !== -1) {
      const caseEntry = caseListData[caseEntryIndex];
      if (caseEntry.status !== caseStatusUpdate) {
        caseEntry.status = caseStatusUpdate;
        caseListData[caseEntryIndex] = caseEntry;
        setCaseList(parseInputData(caseListData));
        websocketClearMessage();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocketUpdate]);

  const pageChange = (page, pageSize) => {
    setCurrentPage(page);
    setSelectedPageSize(pageSize);
    const nextRequest = getNextRequestQuery(
      page,
      pageSize,
      activeFilters.current,
      sortedInfo
    );

    getCaseList(nextRequest);
  };

  const getDateFilterValueFromFilterState = columnKey => {
    let dateFilterValue = [null, null];
    const filterValue =
      activeFiltersObject && activeFiltersObject[columnKey]
        ? activeFiltersObject[columnKey]
        : null;
    if (filterValue) {
      let tmpFilterValue = filterValue[0].split("-");
      dateFilterValue = [
        moment(new Date(tmpFilterValue[0])),
        moment(new Date(tmpFilterValue[1]))
      ];
    }
    return dateFilterValue;
  };

  const rangePicker = (setSelectedKeys, confirm, columnKey) => (
    <RangePicker
      value={filtersCleared ? "" : getDateFilterValueFromFilterState(columnKey)}
      onChange={e => {
        setDateFilter(e, setSelectedKeys, confirm);
      }}
      format={SHORT_DATE_FORMAT}
      separator={<MinusOutlined />}
    />
  );

  const clearFiltersButton = () => (
    <BaseButton
      config={{
        type: BUTTON_CONSTS.TYPE.LINK,
        size: BUTTON_CONSTS.SIZE.SMALL,
        disabled: filtersCleared
      }}
      onClick={() => {
        setFiltersCleared(true);
        setActiveFiltersObject([]);
        filterCases(
          [],
          getCaseList,
          activeFilters,
          setFiltersCleared,
          selectedPageSize,
          sortedInfo,
          setCurrentPage,
          getTotalCases
        );
        selectedDropdownFilters.current = {
          [SURGERY_CASE_ATTR.SURGERY_STATUS]: [],
          [SURGERY_CASE_ATTR.CASE_STATUS]: []
        };
      }}
      text="Clear filters"
    />
  );

  const actionButtons = (_text, value) => {
    return (
      <div className="action-link">
        <Tooltip title={getShareCaseTooltip(value).message} placement="top">
          <Icon
            component={
              getShareCaseTooltip(value).isDisabled
                ? DisabledShareCase
                : EnabledShareCase
            }
            onClick={() => {
              isShareCaseRequested.current = true;
              if (!getShareCaseTooltip(value).isDisabled) {
                setCaseNumber(value.caseNumber);
                setCaseId(value.id);
                setShareCaseModalVisible(true);
              }
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const columns = [
    {
      title: "Case #",
      dataIndex: SURGERY_CASE_ATTR.CASE_NUMBER,
      key: SURGERY_CASE_ATTR.CASE_NUMBER,
      ellipsis: true,
      width: "14rem",
      filterDropdown: filter => inputSearch(filter, "Case #"),
      filterIcon: filter => iconFiltered(filter),
      sorter: (recordA, recordB) =>
        sortFields(recordA.caseNumber, recordB.caseNumber),
      filteredValue: activeFiltersObject[SURGERY_CASE_ATTR.CASE_NUMBER] || null,
      render: (text, record) => {
        return {
          children: (
            <div className="case-number-column">
              <span title={record.caseNumber}>
                {getCompressedCaseNumber(record.caseNumber)}
              </span>
              <Tooltip title="Copy">
                <CopyOutlined
                  onClick={async () => {
                    isCopyCaseNumber.current = true;
                    await copyToClipboard(
                      record.caseNumber,
                      "Case number",
                      message
                    );
                  }}
                />
              </Tooltip>
              {sharedCases && sharedCases.includes(record.caseNumber) && (
                <Tooltip title="Shared case">
                  <SharedCase className="shared-case-icon" />
                </Tooltip>
              )}
            </div>
          )
        };
      }
    },
    {
      title: "Patient Name",
      dataIndex: SURGERY_CASE_ATTR.PATIENT_NAME,
      key: SURGERY_CASE_ATTR.PATIENT_NAME,
      ellipsis: true,
      width: "10.5rem",
      filterDropdown: filter => inputSearch(filter, "Patient Name"),
      filterIcon: filter => iconFiltered(filter),
      sorter: (recordA, recordB) =>
        sortFields(recordA.patientName, recordB.patientName),
      filteredValue: activeFiltersObject[SURGERY_CASE_ATTR.PATIENT_NAME] || null
    },
    {
      title: "Surgery Date",
      dataIndex: SURGERY_CASE_ATTR.SURGERY_DATE,
      key: SURGERY_CASE_ATTR.SURGERY_DATE,
      ellipsis: true,
      width: "7.5rem",
      sorter: (recordA, recordB) => {
        if (isNaN(new Date(recordA.surgeryDate))) return 1;
        if (isNaN(new Date(recordB.surgeryDate))) return -1;
        return new Date(recordA.surgeryDate) - new Date(recordB.surgeryDate);
      },
      filterDropdown: ({ setSelectedKeys, confirm }) =>
        rangePicker(setSelectedKeys, confirm, SURGERY_CASE_ATTR.SURGERY_DATE),
      filteredValue:
        activeFiltersObject[SURGERY_CASE_ATTR.SURGERY_DATE] || null,
      filterIcon: filtered => iconFilteredCalendar(filtered),
      render: (text, record) => {
        let surgeryDate = record.surgeryDate;
        if (surgeryDate !== "-") {
          surgeryDate = new Date(record.surgeryDate);
          surgeryDate = parseDateToFilterFormat(surgeryDate);

          const dateIsToday =
            surgeryDate === parseDateToFilterFormat(new Date());
          if (dateIsToday) {
            surgeryDate = "Today";
          }
        } else {
          if (record.isDeclined) {
            surgeryDate = "";
          }
        }

        const declinedTag = record.isDeclined ? (
          <Tooltip title="Surgery Declined">
            <Tag
              className="declined"
              style={{
                marginLeft: "0.2rem"
              }}
            >
              D
            </Tag>
          </Tooltip>
        ) : null;

        const surgeryDateStyle = record.isDeclined ? { color: "#BFBFBF" } : {};

        return {
          children: (
            <div>
              <span style={surgeryDateStyle}>{surgeryDate}</span>
              {declinedTag}
            </div>
          )
        };
      }
    },
    {
      title: "Surgery status",
      dataIndex: SURGERY_CASE_ATTR.SURGERY_STATUS,
      key: SURGERY_CASE_ATTR.SURGERY_STATUS,
      ellipsis: true,
      width: "5.8125rem",
      filters: surgeryStatusFilters,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) =>
        selectSearch(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          surgeryStatusFilters,
          selectedDropdownFilters,
          SURGERY_CASE_ATTR.SURGERY_STATUS
        ),
      render: text => {
        const tagText = text.toUpperCase();
        return {
          props: {
            className: `status-${tagText.toLowerCase()}`
          },
          children: <Tag>{tagText}</Tag>
        };
      },
      sorter: (recordA, recordB) =>
        sortFields(
          recordA.isDeclined ? newCaseState.DECLINED : recordA.state,
          recordB.isDeclined ? newCaseState.DECLINED : recordB.state
        ),
      filteredValue:
        activeFiltersObject[SURGERY_CASE_ATTR.SURGERY_STATUS] || null
    },
    {
      title: "Comment",
      dataIndex: SURGERY_CASE_ATTR.CLASSIFICATION,
      key: SURGERY_CASE_ATTR.CLASSIFICATION,
      ellipsis: true,
      width: "9.375rem",
      filterDropdown: filter => inputSearch(filter, "Comment"),
      filterIcon: filter => iconFiltered(filter),
      sorter: (recordA, recordB) =>
        sortFields(recordA.classification, recordB.classification),
      filteredValue:
        activeFiltersObject[SURGERY_CASE_ATTR.CLASSIFICATION] || null
    },
    {
      title: "Last modified",
      dataIndex: SURGERY_CASE_ATTR.LAST_MODIFIED,
      key: SURGERY_CASE_ATTR.LAST_MODIFIED,
      ellipsis: true,
      width: "7.125rem",
      defaultSortOrder: SORT_DIRECTIONS.DESCEND,
      sorter: (recordA, recordB) => {
        if (isNaN(new Date(recordA.lastModified))) return 1;
        if (isNaN(new Date(recordB.lastModified))) return -1;
        return new Date(recordA.lastModified) - new Date(recordB.lastModified);
      },
      filterDropdown: ({ setSelectedKeys, confirm }) =>
        rangePicker(setSelectedKeys, confirm, SURGERY_CASE_ATTR.LAST_MODIFIED),
      filteredValue:
        activeFiltersObject[SURGERY_CASE_ATTR.LAST_MODIFIED] || null,
      filterIcon: filtered => iconFilteredCalendar(filtered),
      render: (text, record) => {
        let lastModified = record.lastModified;

        if (lastModified !== "-") {
          lastModified = new Date(record.lastModified);
          lastModified = parseDateToFilterFormat(lastModified);
          const dateIsToday =
            lastModified === parseDateToFilterFormat(new Date());
          if (dateIsToday) {
            lastModified = "Today";
          }
        }
        return {
          children: <div>{lastModified}</div>
        };
      }
    },
    {
      title: "Case status",
      dataIndex: SURGERY_CASE_ATTR.CASE_STATUS,
      key: SURGERY_CASE_ATTR.CASE_STATUS,
      ellipsis: true,
      width: "8.125rem",
      filters: caseStatusFilters.filter(filter => {
        if (filter.value !== "ARCHIVED") return filter;
      }),
      render: (text, record) => {
        const caseStatus = record.caseStatus;
        const formatedStatus =
          (caseStatus && caseStatus.replace(/_/g, " ")) || "-";
        return {
          children: <div className={caseStatus}>{formatedStatus}</div>
        };
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) =>
        selectSearch(
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          caseStatusFilters.filter(filter => {
            if (filter.value !== "ARCHIVED") return filter;
          }),
          selectedDropdownFilters,
          SURGERY_CASE_ATTR.CASE_STATUS
        ),
      sorter: (recordA, recordB) =>
        sortFields(recordA.caseStatus, recordB.caseStatus),
      filteredValue: activeFiltersObject[SURGERY_CASE_ATTR.CASE_STATUS] || null
    },
    {
      title: clearFiltersButton,
      dataIndex: "actions",
      key: "actions",
      width: "5rem",
      render: actionButtons
    }
  ];

  return (
    <div className="cases-list-container">
      <Header title="Cases" />
      <Layout>
        <Content>
          <div className="table-container">
            <Table
              tableLayout="auto"
              columns={columns}
              scroll={{ x: true }}
              dataSource={caseList}
              pagination={false}
              className="cases-list"
              sortDirections={sortDirectionsOptions}
              loading={{
                spinning:
                  !status ||
                  !caseCountStatus ||
                  status === requestStatus.LOADING ||
                  caseCountStatus === requestStatus.LOADING,
                indicator: <LoadingOutlined />
              }}
              onChange={(pagination, filters, sorter) => {
                setSortedInfo({
                  columnKey: sorter.columnKey,
                  order: sorter.order
                });
                setActiveFiltersObject(filters);
                filterCases(
                  filters,
                  getCaseList,
                  activeFilters,
                  setFiltersCleared,
                  selectedPageSize,
                  {
                    columnKey: sorter.columnKey,
                    order: sorter.order
                  },
                  setCurrentPage,
                  getTotalCases
                );
                setFiltersCleared(false);
              }}
              onRow={rowValue => {
                return {
                  onClick: () => {
                    if (isCopyCaseNumber.current) {
                      isCopyCaseNumber.current = false;
                      return;
                    }
                    casesInfo.current = {
                      patientName: rowValue[SURGERY_CASE_ATTR.PATIENT_NAME],
                      modality: rowValue[SURGERY_CASE_ATTR.MODALITY],
                      mrn: rowValue[SURGERY_CASE_ATTR.MRN],
                      surgeryDate: rowValue[SURGERY_CASE_ATTR.SURGERY_DATE],
                      state: rowValue[SURGERY_CASE_ATTR.SURGERY_STATUS],
                      comment: rowValue[SURGERY_CASE_ATTR.CLASSIFICATION],
                      lastModified: rowValue[SURGERY_CASE_ATTR.LAST_MODIFIED],
                      caseStatus: rowValue[SURGERY_CASE_ATTR.CASE_STATUS]
                    };
                    if (isShareCaseRequested.current) {
                      isShareCaseRequested.current =
                        !isShareCaseRequested.current;
                      return;
                    }
                    history.push(getCaseSearchPath(rowValue.caseNumber));
                  }
                };
              }}
            />
          </div>
          <Pagination
            current={currentPage}
            onChange={pageChange}
            onShowSizeChange={pageChange}
            total={totalCases}
            defaultPageSize={PAGINATION_SIZE}
            showSizeChanger={true}
            pageSizeOptions={["10", "20", "50"]}
          />
        </Content>
      </Layout>
      <ShareCaseModal
        isModalVisible={shareCaseModalVisible}
        setModalVisible={setShareCaseModalVisible}
        caseNumber={caseNumber}
        caseId={caseId}
      />
    </div>
  );
};

CaseList.propTypes = {
  history: PropTypes.object,
  getCaseList: PropTypes.func,
  getTotalCases: PropTypes.func,
  caseListData: PropTypes.array,
  totalCases: PropTypes.number,
  status: PropTypes.string,
  caseCountStatus: PropTypes.string,
  clearCaseList: PropTypes.func,
  clearShareCaseStatus: PropTypes.func,
  sharedCases: PropTypes.array,
  acceptCaseInfo: PropTypes.object,
  updateCloneMessageRef: PropTypes.func,
  websocketUpdate: PropTypes.object,
  websocketClearMessage: PropTypes.func
};

const mapStateToProps = state => {
  return {
    caseListData: state.caseList.caseList,
    totalCases: state.caseList.totalCases,
    status: state.caseList.status,
    caseCountStatus: state.caseList.caseCountStatus,
    sharedCases: state.caseList.sharedCases,
    acceptCaseInfo: state.acceptCase,
    websocketUpdate: state.websocket
  };
};

export default connect(mapStateToProps, {
  getCaseList,
  getTotalCases,
  clearCaseList,
  clearShareCaseStatus,
  updateCloneMessageRef,
  websocketClearMessage
})(CaseList);

import { getDownloadUrl } from "./URLHelper";

export const CASE_DETAILS_SECTIONS = {
  PATIENT: "patient",
  SURGERY: "surgery",
  NOTES: "notes",
  PLANNING: "planning",
  SURGERY_DATE: "surgeryDate",
  CT_SCANS: "ctScans",
  SHARED_BY: "sharedBy"
};

const BUTTON_TYPE = {
    PRIMARY: "primary",
    DEFAULT: "default",
    LINK: "link"
  },
  BUTTON_SIZE = {
    LARGE: "large",
    DEFAULT: "default",
    SMALL: "small"
  },
  BUTTON_SHAPE = {
    CIRCLE: "circle",
    ROUND: "round"
  };

export const BUTTON_CONSTS = {
  TYPE: BUTTON_TYPE,
  SIZE: BUTTON_SIZE,
  SHAPE: BUTTON_SHAPE
};

export const WEBSOCKET_TYPES = {
  SURGERY_ADJACENT: "SURGERY_ADJACENT",
  SURGERY_CORE: "SURGERY_CORE"
};

export const WEBSOCKET_STATUS = {
  CONNECTED: "CONNECTED",
  DISCONNECTED: "DISCONNECTED",
  ERROR: "ERROR"
};

export const USER_MANUAL_NAME = "Preview_User_Manual.pdf";

export const DEMO_CT_SCANS = "ER1_Scan4.zip";

export const DOWNLOADS_INFO = getDownloadUrl && [
  {
    downloadUrl: getDownloadUrl(
      "1-Preview-Introduction-and-Coordinate-System.mp4"
    ),
    text: "Introduction and coordinate system"
  },
  {
    downloadUrl: getDownloadUrl("2--Introduction-to-controls.mp4"),
    text: "Introduction to controls"
  },
  {
    downloadUrl: getDownloadUrl("3--Basic-Implant-Controls.mp4"),
    text: "Basic implant controls"
  },
  {
    downloadUrl: getDownloadUrl("4--2D-CT-controls.mp4"),
    text: "2D CT controls"
  },
  {
    downloadUrl: getDownloadUrl("5--Augmented-Glenoid-Implants.mp4"),
    text: "Augmented glenoid implants"
  },
  {
    downloadUrl: getDownloadUrl("6--Measurement-Tool.mp4"),
    text: "Measurement tool"
  },
  {
    downloadUrl: getDownloadUrl("7--Pocket-Tool.mp4"),
    text: "Pocket tool"
  },
  {
    downloadUrl: getDownloadUrl("8-Finalization-and-Post-Report-PDF.mp4"),
    text: "Finalization and post report PDF"
  }
];

export const sharedFeatures = [
  "View the case details in ProVoyance Shoulder App and in the Web Portal.",
  "Edit existing plans in ProVoyance Shoulder App without changing the original case.",
  "Plan this case surgery in ProVoyance Shoulder App.",
  "Share this case in the Web Portal case list."
];

export const SHARE_CASE_TOOLTIP = {
  readyAndSynced: { message: "Share", isDisabled: false },
  readyNotSynced: {
    message: `To enable share you need to: go to "ProVoyance Shoulder" app and Sync this case to the cloud.`,
    isDisabled: true
  },
  notReady: {
    message: "Only cases with status ready can be shared.",
    isDisabled: true
  }
};

export const SHARE_CASE_MODAL = {
  TAG_COLOR: {
    DEFAULT: "default",
    RED: "red"
  },
  PS_EMAIL_TOOLTIP: "You are allowed to share with multiple users",
  SELECT_PLACEHOLDER: "example@email.com, comma separated",
  EMAIL_ERROR: {
    REQUIRED: "* Valid user email is required.",
    FORMAT: "* Invalid email address. Please enter a valid email."
  },
  ALERT_DESCRIPTION:
    "Note: You are requesting to share a de-identified case to a ProVoyance Shoulder user who has also agreed to the data use terms for this application.",
  SHARE_CASE_INFO: "I agree to share case information with an external agent."
};

export const SHARED_CASE_STATUS = {
  PENDING_ACCEPTANCE: "PENDING_ACCEPTANCE",
  ACCEPTED: "ACCEPTED",
  SHARING: "SHARING",
  SHARED: "SHARED"
};

export const DEFAULT_ERROR_MESSAGE = "Please try again";
